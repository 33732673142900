// helpers.js

import Notice from "@ouduidui/notice";
const instance = new Notice();

$(function() {

    $("#header-search").on( 'keyup', (event) => {
        event.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const search_length = $("#header-search").val().length;
        const query = $("#header-search").val();
        let min_length = 3;
        if ( Number.isInteger(parseInt(query)) ){
            min_length = 0;
        }
        let values = {};
        if ( search_length > min_length ) {
            console.log('Mas de 3');
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "query": query, "show_info": true };
        } else {
            console.log('Menos de 3');
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "query": "", "show_info": false };
        }

        if ( window.location.pathname.includes('/lihtusers/') ) {
            console.log('Se cumple users');
            lihtusers_live_search_action(auth_token, values);
        } else if ( window.location.pathname.includes('/drivers/') ) {
            console.log('Se cumple drivers');
            drivers_live_search_action(auth_token, values);
        }

    });



    $(".close-header-search").on( 'click', (event) => {
        event.preventDefault();
        let url = '';
        if ( window.location.pathname.includes('/lihtusers/') ) {
            url = '/es/lihtusers/users/index';
            browse_to_engine(url);
        } else if ( window.location.pathname.includes('/drivers/') ) {
            url = '/es/drivers/drivers/index';
        }
        browse_to_engine(url);
    });
});


window.spin_in = () => {
    instance.showLoading({
        type: 'cube_zoom'
    });
}

window.spin_out = () => {
    instance.hideLoading()
}

/****** Helpers Toast */
// Types of toats => https://www.cssscript.com/demo/loading-spinner-toast-noticekit/
window.toast_msg = (text,type) => {
    instance.showToast({
        text: text,
        type: type
    })
}
window.error_msg = (text) => {
    instance.showToast({
        text: text,
        type: 'error',
        showClose: true,
        autoClose: false
    })
}

/****** Helpers Sweetalert */
window.sweetalert_error = (text, subject, body) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        footer: 'Si el problema persiste <a href="mailto:cs@liht.com?subject=' + subject + '&body=' + body + '"> <strong> PULSE AQUI</strong></a>'
    })
}

window.sweetalert_error_401 = () => {
    Swal.fire({
        icon: 'error',
        title: 'Login',
        text: 'Su sesión ha caducado o no esta correctamente logeado.',
        footer: '<a href="/"> <strong> PULSE AQUI</strong></a> para ir a la página de acceso'
    })
}

window.sweetalert_error_for_actions = (title, text, footer) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        footer: footer
    })
}


window.hide_show = (hide,show,time) => {
    $('.'+hide).fadeOut(time);
    setTimeout(() => {
        $('.'+show).fadeIn(time);
    }, time );
}

