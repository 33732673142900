// lihtconfigs.js

import { event } from "jquery";

$(function() {

    const locale = $("#locale").val();
    const auth_token = localStorage.getItem('auth_token');
    const authenticity_token = $("#authenticity_token").val();

    $(".btn-create-modify-app").on( 'click', (event) => {
        event.preventDefault();
        const id = $("#app_id").val();
        const name = $("#app_name").val();
        const url = $("#app_url").val();

        const values = {"locale": locale, "authenticity_token": authenticity_token, "id": id, "name": name, "url": url };

        if (id != "") {
            lihtconfigs_create_update_apps_action( auth_token, values );
        } else {
            toast_msg( 'Debe escribir un ID numerico', 'warning' );
        }

    });

    $(".btn-create-modify-group").on( 'click', (event) => {
        event.preventDefault();
        const id = $("#group_id").val();
        const name = $("#group_name").val();

        const values = {"locale": locale, "authenticity_token": authenticity_token, "id": id, "name": name };

        if (id != "") {
            lihtconfigs_create_update_groups_action( auth_token, values );
        } else {
            toast_msg( 'Debe escribir un ID numerico', 'warning' );
        }

    });

});


window.lihtconfigs_create_update_apps_action = ( auth_token, values ) => {
    spin_in();
    fetch('/'+values.locale+'/configs/api/v1/create_update_apps',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 200 ) {
            let html = '';
            html += '<td>'+data.app.id+'</td>';
            html += '<td>'+data.app.name+'</td>';
            html += '<td>'+data.app.url+'</td>';

            if ( $("#app-row-"+data.app.id).length == 0 ) {
                $("#lihtconfigs-apps-list").append('<tr>'+html+'</tr>');
                toast_msg('La app se ha creado correctamente', 'success');
            } else {
                $("#app-row-"+data.app.id).empty();
                $("#app-row-"+data.app.id).html(html);
                toast_msg('La app se ha actualizado correctamente', 'success');
            }

            $("#app_id").val('');
            $("#app_name").val('');
            $("#app_url").val('');
            

        } else {
            // Verify code error
            toast_msg('Se ha producido un error durante el proceso', 'warning');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}


window.lihtconfigs_create_update_groups_action = ( auth_token, values ) => {
    spin_in();
    fetch('/'+values.locale+'/configs/api/v1/create_update_groups',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 200 ) {
            let html = '';
            html += '<td>'+data.group.id+'</td>';
            html += '<td>'+data.group.name+'</td>';

            if ( $("#group-row-"+data.group.id).length == 0 ) {
                $("#lihtconfigs-groups-list").append('<tr>'+html+'</tr>');
                toast_msg('El grupo se ha creado correctamente', 'success');
            } else {
                $("#group-row-"+data.group.id).empty();
                $("#group-row-"+data.group.id).html(html);
                toast_msg('El grupo se ha actualizado correctamente', 'success');
            }

            $("#group_id").val('');
            $("#group_name").val('');
            

        } else {
            // Verify code error
            toast_msg('Se ha producido un error durante el proceso', 'warning');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}