// lihtlogin.js

import fetch from '../../fetch_with_timeout'
import { lihtlogin_langs_recover_password } from '../../languages'

$(function() {

    check_remember();

    $(".go-forgot").on( 'click', (event) =>  {
        event.preventDefault();
        hide_show('login-layer', 'forgot-layer', 200);
    });
        

    $(".go-login").on( 'click', (event) =>  {
        event.preventDefault();
        hide_show('forgot-layer', 'login-layer', 200);
    });

    $(".btn-login").on( 'click', (event) =>  {
        event.preventDefault();
        let remember_btn = 0;
        if($('#remember').prop('checked')){
            remember_btn = 1;
        }
        const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "email": $("#email").val(), "password": $("#password").val(), "remember": remember_btn }
        lihtlogin_login_action( values );
    });

    $(".btn-forgot-password").on( 'click', (event) =>  {
        event.preventDefault();
        const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "email": $("#forgot-email").val() }
        lihtlogin_forgot_password_action( values );
    });

    $(".btn-verify-code").on( 'click', (event) =>  {
        event.preventDefault();
        const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_email": $("#user_email").val(), "verify_code": $("#verify_code").val(), "previous_ip": $("#previous_ip").val() }
        lihtlogin_verify_code_action( values );
    });

    $(".request-new-code").on( 'click', (event) =>  {
        event.preventDefault();
        const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_email": $("#user_email").val() }
        lihtlogin_request_new_verify_code_action( values );
    });

    $(".btn-restore-password").on( 'click', (event) =>  {
        event.preventDefault();
        const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "email": $("#forgot_password_user_email").val(), "password": $("#password-restore").val(), "key": $("#key").val()  }
        if (verify_password_restore_params(values)) {
            lihtlogin_restore_password_action( values );
        }
    });

    $("#btn-logout").on( 'click', (event) =>  {
        event.preventDefault();
        lihtlogin_logout_action();
    });

    $("#lihtlogin-toggle-password-text").on( 'mouseover', () => {
        $("#lihtlogin-toggle-password-text").removeClass( 'fa-lock' ).addClass( 'fa-eye' );
    });

    $("#lihtlogin-toggle-password-text").on( 'mouseout', () => {
        $("#lihtlogin-toggle-password-text").removeClass( 'fa-eye' ).addClass( 'fa-lock' );
    });

    $("#lihtlogin-toggle-password-text").on( 'click', () => {
        if ( $("#password").attr( 'type' ) == "password" ){
            $("#password").attr( 'type', 'text' );
        } else {
            $("#password").attr( 'type', 'password' );
        }
    });

});

window.check_remember = () => {
    if (localStorage.getItem('user_email') != null) {
        $("#email").val( localStorage.getItem('user_email') );
        $('#remember').prop('checked', true);
    }
}

window.verify_password_restore_params = (values) => {
    const key = $("#key").val();
    const password = $("#password-restore").val();
    const password2 = $("#password-restore-confirm").val();
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    if (key.length != 128) {
        $(document).Toasts('create', {
            class: 'bg-danger',
            position: 'topRight',
            icon: "fas fa-exclamation-triangle",
            delay: 3000,
            title: lihtlogin_langs_recover_password[values.locale].key.title,
            subtitle: lihtlogin_langs_recover_password[values.locale].key.subtitle,
            body: lihtlogin_langs_recover_password[values.locale].key.body
        });

        return false;
    }
    if (password != password2) {
        $(document).Toasts('create', {
            class: 'bg-danger',
            position: 'topRight',
            icon: "fas fa-exclamation-triangle",
            delay: 3000,
            title: lihtlogin_langs_recover_password[values.locale].equals.title,
            subtitle: lihtlogin_langs_recover_password[values.locale].equals.subtitle,
            body: lihtlogin_langs_recover_password[values.locale].equals.body
        });

        return false;
    }

    if (strongRegex.test(password)){
        return true;
    } else {
        $(document).Toasts('create', {
            class: 'bg-danger',
            position: 'topRight',
            icon: "fas fa-exclamation-triangle",
            delay: 3000,
            title: lihtlogin_langs_recover_password[values.locale].regex.title,
            subtitle: lihtlogin_langs_recover_password[values.locale].regex.subtitle,
            body: lihtlogin_langs_recover_password[values.locale].regex.body
        });

        return false;
    }

    return true;

}

window.lihtlogin_login_action = (values) => {
    spin_in();
    fetch('/'+values.locale+'/login/api/v1/login',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            $("#error-login-title").text(data.error_title);
            $("#error-login-msg").text(data.error_msg);
            $(".login-alert-wrapper").fadeIn(500);            

            setTimeout(() => {
                $(".login-alert-wrapper").fadeOut(500);
            }, 5000);

        } if ( data.status == 201 ) {
            hide_show('login-layer', 'code-layer', 200);
            $("#verify-code-title").show();
            $("#user_email").val(data.user.email);
            $("#previous_ip").val(data.ip);
        } else {
            localStorage.setItem('auth_token', data.auth_token);
            if(values.remember == 1){
                localStorage.setItem('user_email', data.user.email);
            } else {
                localStorage.removeItem('user_email');
            }

            const url = data.user.app.url; // For redirect to init app

            let str = '';
            str += '<form action="/'+data.locale+url+'" method="post" id="login-redirect-form">';
            str += '<input type="hidden" name="auth_token" value="' + data.auth_token + '">';
            str += '<input type="hidden" name="authenticity_token" value="' + values.authenticity_token + '">';
            str += '</form>';
            $("#login-redirect").html(str);

            $("#login-redirect-form").trigger("submit");
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtlogin_verify_code_action = (values) => {
    spin_in();
    fetch('/'+values.locale+'/login/api/v1/verify_code',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 200 ) {
            localStorage.setItem('auth_token', data.auth_token);
            let str = '';
            str += '<form action="/'+data.locale+'/lihtusers/users/index" method="post" id="redirect-form">';
            str += '<input type="hidden" name="auth_token" value="' + data.auth_token + '">';
            str += '<input type="hidden" name="authenticity_token" value="' + values.authenticity_token + '">';
            str += '</form>';
            $("#redirect").html(str);

            $("#redirect-form").trigger("submit");

        } else if ( data.status == 201 )  {
            // Difference between original IP and second IP
            $("#verify-code-title").hide();
            $("#error-verify-title").text(data.error_title);
            $("#error-verify-msg").text(data.error_msg);
            $(".login-alert-wrapper-verify-code").fadeIn(500);

        } else {
            // Verify code error
            $("#verify-code-title").hide();
            $("#error-verify-title").text(data.error_title);
            $("#error-verify-msg").text(data.error_msg);
            $(".login-alert-wrapper-verify-code").fadeIn(500);
            setTimeout(() => {
                hide_show('code-layer', 'login-layer', 200);
            }, 5000);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtlogin_request_new_verify_code_action = (values) => {
    spin_in();
    fetch('/'+values.locale+'/login/api/v1/request_new_verify_code',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 200 ) {
            $("#verify-code-title").html('<strong>'+data.error_title+'</strong><br>'+data.error_msg).show();
            $(".login-alert-wrapper-verify-code").hide();
            
        } else {
            // Verify code error
            $("#verify-code-title").hide();
            $("#error-verify-title").text(data.error_title);
            $("#error-verify-msg").text(data.error_msg);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtlogin_forgot_password_action = (values) => {
    spin_in();
    fetch('/'+values.locale+'/login/api/v1/forgot_password',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 200 ) {
            $("#forgot_password_user_email").val(data.email);
            $("#key").val("");
            $("#password-restore").val("");
            $("#password-restore-confirm").val("");
            hide_show('forgot-layer', 'recover-layer', 200);
            $("#login-alert-wrapper-forgot-password").hide();
        } else {
            // Verify code error
            $("#forgot-password-title").hide();
            $("#error-forgot-title").text(data.error_title);
            $("#error-forgot-msg").text(data.error_msg);
            $(".login-alert-wrapper-forgot-password").show();
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

window.lihtlogin_restore_password_action = (values) => {
    spin_in();
    fetch('/'+values.locale+'/login/api/v1/restore_password',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            hide_show('recover-layer', 'login-layer', 200);
            $("#error-login-title").text(data.error_title);
            $("#error-login-msg").text(data.error_msg);
            $(".login-alert-wrapper").fadeIn(500);            

            setTimeout(() => {
                $(".login-alert-wrapper").fadeOut(500);
            }, 5000);

        } if ( data.status == 201 ) {
            hide_show('recover-layer', 'code-layer', 200);
            $("#verify-code-title").show();
            $("#user_email").val(data.user.email);
            $("#previous_ip").val(data.ip);
        } else {
            localStorage.setItem('auth_token', data.auth_token);
            if(values.remember == 1){
                localStorage.setItem('user_email', data.user.email);
            } else {
                localStorage.removeItem('user_email');
            }

            let str = '';
            str += '<form action="/'+data.locale+'/'+data.user.app.url+'" method="post" id="redirect-form-reset-password">';
            str += '<input type="hidden" name="auth_token" value="' + data.auth_token + '">';
            str += '<input type="hidden" name="authenticity_token" value="' + values.authenticity_token + '">';
            str += '</form>';
            $("#login-redirect").html(str);

            $("#redirect-form-reset-password").trigger("submit");
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtlogin_logout_action = () => {
    spin_in();
    setTimeout(() => {
        spin_out();
        localStorage.removeItem('auth_token');
        window.location.href = '/';
    }, 1000);

}